import axios from 'axios'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import store from '../store'
var CryptoJS = require('crypto-js')
import JSEncrypt from 'jsencrypt'
var domain = document.domain;
let baseApi="/api"
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base url + request url
  withCredentials: false, // 跨域请求时发送 cookies
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
   let token=Cookies.get("AuthorizationToken")
    if (token) {
      config.headers['Authorization'] = token
    }
      if(config.url!='/system/user/info'){
          config.params['region']=store.state.cityId
      }else{
      }
    return config
  },
  error => {
    // do something with request error
    console.log('A', error) // for debug
    return Promise.reject(error)
  }
)

// 加密头标识
const headFlag = 'encrypt-key'
const private_key = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAJpk1P+RkY5kghqOK1uIQfxwbRNJtnHJB6gNxGaZ/FdkMnLxX2+yp2Z8GkHCV4sHnDeOu5+O9KsegmbKHSsN5uFch/AhEVwnkDrqObmHXU0aWXgmtwsgWRp4TH6XYm2Rdua5kuCA9BQoA+85EoOuBw68+lyC5s9tZDQQgrv5euI1AgMBAAECgYAMY/YyEnEff9JvJiS1olBFXZJrX1mMdSkvo/c0krwNGWXxEozprmk/0MrcpyeWaURwsvw8uGDLUNX/QM4pc+Ko6HQ3KCTshlCQZeMGNwm/TsEOTWjRrUDAzA3vk+6vxyEOiNpNMVXFPXCXuPG4t5+AnK01+2k6vv98fntuGt+xUQJBAMuxq3pMreyCGetHceHfaaGu31+SO0uTIeG3K2s1dJnkapNm4XAoQuJiTkuOHoaQCsj/oiU2kcE8s2ZSeESrzgkCQQDCCkuLrWgBz3mulvZ61LrtDVptrOZQoJZNAGBk1+3apMdB2dUs1YY064gs5KoCx0qeZSVFqFkdCrybr/r5CP3NAkAvQIj59v3FxqyCkvFNJ4mWkz2IKyEMOBZ9CQPOe/kPlhhPF2emoABcCdgF2PMl3hZSaFLR9SW8wPsQ+oKh04UZAkEAts0WIK+rtVDttNIZwNwageq7j2l8zOaM0lwRc8+NEBd+dIYwc31xYveloY2GsPlpTdHCDF6uHVMZHp7qlKSo3QJBAKY/kEJFpTMVOKcMnB6iGiU3HGGE2L1FohY2kVi/yRzdDrRsWP2OoyBCxK2ToKBoARZp+QtZq7gf5DZJoqNzI1s='
// response interceptor
service.interceptors.response.use(
  response => {
    let res = response.data
      if(res.code==401){//登录超时
          window.location.href='/#/login'
      }
      if (res.encrypt) {
          const aesKey = response.headers[headFlag]
          var encryptor = new JSEncrypt()
          encryptor.setPrivateKey(private_key)
          const encryptKey = encryptor.decrypt(aesKey)
          const decryptAESData = decryptByAes(res.encryptData, encryptKey)
          res = JSON.parse(decryptAESData)
      }
    return res
  },
  error => {
    console.log('B', error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

function decryptByAes(ciphertext, key) {
    ciphertext = ciphertext.replace(/\n*$/g, '').replace(/\n/g, '')
    var word = CryptoJS.enc.Base64.parse(key)
    var keyHex = CryptoJS.enc.Utf8.parse(CryptoJS.enc.Utf8.stringify(word))
    var decrypt = CryptoJS.AES.decrypt(ciphertext, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
}

export default service
